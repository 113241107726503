import React from "react"
import PropTypes from "prop-types"

import * as styles from "./styles.module.scss"

import Container from "@layout/container"
import Image from "@components/image"
import Grid from "@layout/grid"

const TwoColumnCallout = ({ callouts, heading }) => {
  const contentColumn = (body) => <div dangerouslySetInnerHTML={{ __html: body }} />
  const imgColumn = (image) => <Image className={styles.callout_image} src={image} />

  return (
    <Container
      className={styles.two_column_callout}
      hasChildren
      theme="background-is-white"
      greenBorder={false}
    >
      <h2>{heading}</h2>

      {callouts.map((callout, index) => {
        const { body, image } = callout

        return (
          <Grid
            key={index}
            className={styles.callout_content}
            items={`${callouts.length}`}
            hasChildren
            gutter="15"
            layout="2"
          >
            {image && imgColumn(image)}
            {body && contentColumn(body)}
          </Grid>
        )
      })}
    </Container>
  )
}

TwoColumnCallout.propTypes = {
  /**
   * An array of callouts
   */
  callouts: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Specifies the markdown content
       */
      body: PropTypes.string,
      /**
       * Specifies the  image
       */
      image: PropTypes.object
    })
  ),
  heading: PropTypes.string
}

TwoColumnCallout.defaultProps = {}

export default TwoColumnCallout

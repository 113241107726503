import imageFixture from "../image/fixtures"
const children =
  "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in neque vel mi pellentesque aliquet a et dolor. Etiam ornare tincidunt nisi sit amet iaculis.</p>"

export default {
  props: {
    callouts: [
      {
        body: children,
        image: imageFixture.default.src
      },
      {
        body: children,
        image: imageFixture.default.src
      }
    ]
  }
}

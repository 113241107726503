import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { component as Footer } from "@layout/footer"
import { component as Header } from "@layout/header"
import Layout from "@layout/no-header-footer"
import TwoColumnCallout from "@components/two-column-callout"
import Jumbotron from "@components/jumbotron"

import * as styles from "./styles.module.scss"

class AboutPage extends React.Component {
  // data alias
  page = this.props.data.datoCmsAboutTruckVan

  getFooterCallout() {
    const footerCallout = this.page.footerCallout
    const jumbotron = {
      backgroundImage: {
        childImageSharp: {
          gatsbyImageData: {
            ...footerCallout.backgroundImage.gatsbyImageData
          }
        }
      },
      body: footerCallout.body,
      buttons: footerCallout.buttons,
      theme: footerCallout.theme
    }

    return (
      <div className={styles.aboutFooterCallout}>
        <Jumbotron {...jumbotron}></Jumbotron>
      </div>
    )
  }

  render() {
    const jumbotron = {
      backgroundImage: {
        childImageSharp: {
          gatsbyImageData: {
            ...this.page.jumbotron.backgroundImage.gatsbyImageData
          }
        }
      },
      body: this.page.jumbotron.body,
      heading: this.page.jumbotron.heading
    }

    const callouts = this.page.callouts.map((item) => {
      return {
        body: item.bodyNode ? item.bodyNode.childMarkdownRemark.html : item.body,
        image: {
          childImageSharp: {
            gatsbyImageData: {
              ...item.image.gatsbyImageData
            }
          }
        }
      }
    })

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>
        <Header {...this.props.data.menu} />

        <div className={styles.aboutJumbo}>
          <Jumbotron {...jumbotron}></Jumbotron>
        </div>

        <Layout seoMetaTags={this.page.seoMetaTags}>
          <div className={styles.aboutContent}>
            <TwoColumnCallout callouts={[...callouts]} />
          </div>
        </Layout>

        {this.getFooterCallout()}

        <Footer {...this.props.data.footer} />
      </>
    )
  }
}

export default AboutPage

export const query = graphql`
  query AboutTruckVanQuery {
    ...MegaMenu
    ...Footer

    datoCmsAboutTruckVan {
      permalink
      title
      seoMetaTags {
        tags
      }
      jumbotron {
        backgroundImage {
          alt
          url
          title
          gatsbyImageData
        }
        buttons {
          label
          link
          theme
        }
        body
        heading
      }
      callouts {
        bodyNode {
          childMarkdownRemark {
            html
          }
        }
        body
        image {
          gatsbyImageData
        }
      }
      footerCallout {
        backgroundImage {
          alt
          url
          title
          gatsbyImageData
        }
        theme
        body
        buttons {
          label
          link
          theme
        }
      }
    }
  }
`
